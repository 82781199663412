<template>
  <div id="app">
    <Header />
    <transition name="fade">
      <router-view />
    </transition>
    <transition name="fade">
      <Footer v-if="showFooter" ref="footer"/>
    </transition>
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  components:{
    Header,
    Footer,
  },
  data(){
    return{
      showFooter: false,
      showPage: false,
    }
  },
  mounted() {
    setTimeout(() =>{
      this.showFooter = true
    }, 100)
    setTimeout(() =>{
      document.querySelector("body").style.transition = "background 5s ease-out"
      document.querySelector("body").style.background = "linear-gradient(90deg, #0A081C 0%, #0E1E38 50%, #0D0D17 100%)"
    }, 500)
  }
}
</script>

