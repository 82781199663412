import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/about-us/about-company',
    name: 'Tentang Perusahaan',
    component: () => import('../views/tentangKami/TentangPerusahaan.vue')
  },
  {
    path: '/about-us/aturan-perdagangan',
    name: 'Aturan Perdagangan',
    component: () => import('../views/tentangKami/AturanPerdagangan.vue')
  },
  {
    path: '/about-us/syarat-ketentuan',
    name: 'Syarat dan Ketentuan',
    component: () => import('../views/tentangKami/SyaratKetentuan.vue')
  },
  {
    path: '/about-us/kebijakan-privasi',
    name: 'Kebijakan Privasi',
    component: () => import('../views/tentangKami/KebijakanPrivasi.vue')
  },
  {
    path: '/about-us/perjanjian-pengguna',
    name: 'Perjanjian Pengguna',
    component: () => import('../views/tentangKami/PerjanjianPengguna.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
