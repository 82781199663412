<template>
    <header :class="`container relative ${isHeaderFill ? 'fill':''}`">
        <div class="container container__header">
            <div class="logo__wrapper"><a href="/"><img src="@/assets/image/logo.png" alt="mnc_crypto"></a></div>
            <!-- ham button -->
            <div class="btn_ham__wrapper">
                <a @click="openMobileMenu" :class="`ham__btn ${isOpenMobileMenu ? 'active':''}`"><i></i></a>
            </div>
            <nav class="top__nav">
                <a @click="goToPage('Home')"  :class="`item_menu__desktop ${$route.name == 'Home' ? 'active':''}`">Home</a>
                <a @click="goToPage('Tentang Kami')" :class="`item_menu__desktop ${$route.path.includes('/about-us') || isOpenSubmenu ? 'active':''}`"><span>Tentang Kami</span><img class="icon__dropdown" src="@/assets/image/icon-dropdown.svg"></a>
            </nav>
            <!-- submenu -->
            <transition name="fade">
                <nav v-if="isOpenSubmenu" class="sub__menu__desktop">
                    <a v-for="(item, index) in submenu" :key="index" @click="goToPage(item.name)" :class="$route.name == item.name ? 'active': ''"><h6>{{item.name}}</h6><p>{{item.desc}}</p></a>
                    <div class="triangle"></div>
                </nav>
            </transition>
            <!-- <transition name="slide-down"> -->
                <nav :class="`menu__mobile ${isOpenMobileMenu ? 'active':''}`">
                    <div class="parent__menu__mobile"><a @click="goToPage('Home')" :class="`${$route.name == 'Home' ? 'active':''}`">Home</a></div>
                    <div class="parent__menu__mobile"><a :class="`${$route.path.includes('/about-us') ? 'active':''}`">Tentang Kami</a></div>
                    <div class="submenu__mobile">
                        <a v-for="(item, index) in submenu" :key="index" @click="goToPage(item.name)" :class="$route.name == item.name ? 'active': ''"><h6>{{item.name}}</h6><p>{{item.desc}}</p></a>
                    </div>
                </nav>
            <!-- </transition> -->
        </div>
    </header>
</template>

<script>
export default {
    data (){
        return{
            isOpenSubmenu: false,
            isOpenMobileMenu: false,
            submenu: [
                {
                    name: 'Tentang Perusahaan',
                    desc: 'Inovasi dan kemudahan adalah misi kami, lihat kisahnya di sini!',
                },
                {
                    name: 'Syarat dan Ketentuan',
                    desc: 'Seluruh syarat dan ketentuan yang belaku di MotionCrypto.',
                },
                {
                    name: 'Aturan Perdagangan',
                    desc: 'Temukan semua aturan perdagangan yang mengatur transaksi Anda.',
                },
                {
                    name: 'Perjanjian Pengguna',
                    desc: 'Perjanjian yang akan mengikat Anda dan MotionCrypto.',
                },
                {
                    name: 'Kebijakan Privasi',
                    desc: 'Cara kami mengumpulkan, menggunakan, mengungkap dan mengelola data Anda.',
                },
            ],
            isHeaderFill: false
        }
    },
    methods: {
        goToPage (name) {
            if(name == 'Tentang Kami') {
                this.isOpenSubmenu = !this.isOpenSubmenu
                return
            }
            
            this.$router.push({ name: name})
            window.scrollTo(0,0);
            this.isOpenSubmenu = false
            this.isOpenMobileMenu = false
        },
        openMobileMenu(){
            const doc = document.documentElement;
            const y = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0)
            this.isOpenMobileMenu = !this.isOpenMobileMenu
            this.isOpenMobileMenu ? this.isHeaderFill = true : y > 50 ? this.isHeaderFill = true : this.isHeaderFill = false
        },
        handleScroll () {
            const doc = document.documentElement;
            const y = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0)
            y > 50 ? this.isHeaderFill = true : this.isHeaderFill = false
        },
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll);
    },
}
</script>